import * as React from "react"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/benefits-style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { organizationSchema } from "../const/schemas"
import Benefits from "../components/benefits/benefits"
import { languages } from "../const/languages"

const BenefitsPage = () => <Benefits lang={languages.PL} />

export const Head = () => {
  return (
    <>
      <Seo
        title="Odkryj moc korzyści z rozwiązań AR."
        description="Przekonaj się, jak rozszerzona rzeczywistość może pomóc Twojej marce wyróżnić się na rynku i zapewnić Twoim klientom zrównoważone i angażujące doświadczenie zakupowe."
        canonicalURL="https://staging-area.ar-labs.io/pl/benefits"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default BenefitsPage
